@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
.form {
  padding: 0;
  margin: 0 auto 2em;
  display: grid;
  grid-gap: 2rem;
  max-width: 80rem;
  /* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}

@media screen and (min-width: 45rem) {
  .form {
    grid-template-columns: auto auto;
  }
}

.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 40rem) {
  .form__section.-full {
    grid-column: span 2;
  }
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-column: 1 / -1;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}

.form__message {
  font-weight: bold;
}

.form__message.-success  {
  color: #54b351;
}

.form__message.-error {
  color: #fc4b3f;
}

.contact-form__heading {
  font-size: 2.5rem;
  margin-top: 2rem;
}

.contact-form__input {
  border-radius: 5px;
  border: 1px #d2d2d2 solid;
  background-color: #ebebeb;
  background-image: none;
  box-shadow: none;
  height: 50px;
  padding: .75rem 1rem;
}

.contact-form__textarea {
  resize: vertical;
  min-height: 200px;
}

/*
* LAYOUT
*/
.breadcrumb-nav {
  background-color: #182a3b;
}

.breadcrumb-nav__list {
  margin: 0;
  padding: 23px 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: flex-start;
  color: white;
}

@media screen and (min-width: 800px) {
  .breadcrumb-nav__list {
    justify-content: flex-end;
  }
}

.breadcrumb-nav__item {
  font-size: 0.875rem;
}

.breadcrumb-nav__item:not(:last-child)::after {
  content: "\>";
  margin: 0 0.6em 0 0.75em;
}

@media screen and (min-width: 800px) {
  .breadcrumb-nav__item:not(:last-child)::after {
    margin: 0 1.2em 0 1.5em;
  }
}

.breadcrumb-nav__link {
  text-decoration: underline;
  display: inline-block;
}

.breadcrumb-nav a,
.breadcrumb-nav a:link,
.breadcrumb-nav a:visited {
  color: white;
  text-decoration: underline;
}

.breadcrumb-nav [aria-current="location"],
.breadcrumb-nav [aria-current="location"]:link,
.breadcrumb-nav [aria-current="location"]:visited {
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}

.breadcrumb-nav a:hover,
.breadcrumb-nav a:focus,
.breadcrumb-nav a:active {
  text-decoration: none;
}

.categories-overview {
  background-color: #132332;
  color: white;
  padding: 25px 0;
}

@media screen and (min-width: 900px) {
  .categories-overview {
    padding: 40px 0;
  }
}

.categories {
  text-align: center;
  display: grid;
  grid-gap: 25px;
  gap: 25px;
  color: white;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 700px) {
  .categories {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 900px) {
  .categories {
    grid-gap: 40px;
    gap: 40px;
  }
}

.categories__item {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  position: relative;
  text-align: left;
  padding: 16px;
  background-color: #182a3b;
  transition: background-color 0.3s ease-out;
}

@media screen and (min-width: 700px) {
  .categories__item {
    padding: 16px 20px;
    grid-template-columns: 54px 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .categories__item {
    padding: 24px 30px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: 74px 1fr;
  }
}

.categories__item:focus-within {
  background-color: #00569d;
}

.categories__item:hover {
  background-color: #00569d;
}

.categories__icon {
  display: block;
}

.categories__icon svg {
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 700px) {
  .categories__icon svg {
    width: 54px;
    height: 54px;
  }
}

@media screen and (min-width: 1200px) {
  .categories__icon svg {
    width: 74px;
    height: 74px;
  }
}

.categories__heading {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.375rem;
  line-height: 1.06667;
  color: #0d92ff;
  font-weight: bold;
  margin: 0;
}

@media screen and (min-width: 700px) {
  .categories__heading {
    font-size: 1.625rem;
  }
}

@media screen and (min-width: 1200px) {
  .categories__heading {
    font-size: 1.875rem;
  }
}

.categories__description {
  font-size: 0.75rem;
  margin-bottom: 0;
}

.categories__link {
  display: inline-flex;
  align-items: center;
}

.categories__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.categories__link::after {
  content: "";
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: inline-block;
  margin-left: 12px;
  width: 20px;
  height: 20px;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='14' height='9' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M10.086,3.481l-10.086,-0l0,2l10.125,-0l-1.832,1.831l1.414,1.415l4.233,-4.246l-4.233,-4.208l-1.414,1.415l1.793,1.793Z' style='fill:%23fff;'/%3E%3C/svg%3E") no-repeat center center;
}

.categories__link:hover::after, .categories__link:focus::after {
  transform: translateX(0.5rem);
}

.categories a,
.categories a:link,
.categories a:visited {
  color: white;
  text-decoration: none;
}

.products-page {
  background-color: #132332;
  color: white;
  padding: 50px 0;
}

@media screen and (min-width: 900px) {
  .products-page {
    padding: 70px 0;
  }
}

.products-page__heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 2.875rem;
  margin: 0 0 3.5rem;
}

.products-page__heading-icon {
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.products-page__heading-icon svg {
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 1200px) {
  .products-page__heading-icon {
    margin-right: 40px;
  }
  .products-page__heading-icon svg {
    width: 60px;
    height: 60px;
  }
}

.products {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-gap: 25px;
  gap: 25px;
}

@media screen and (min-width: 700px) {
  .products {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    gap: 40px;
  }
}

@media screen and (min-width: 1100px) {
  .products {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    gap: 60px;
  }
}

.products__item {
  border: 1px #1e425a solid;
  padding: 25px 25px 35px;
  position: relative;
  font-size: 0.75rem;
  font-weight: 300;
  background: #101e2b url(/assets/images/pattern-products.svg) repeat-x 50% 100%;
  transition: background-color 0.25s ease-out, border-color 0.25s ease-out;
}

@media screen and (min-width: 700px) {
  .products__item {
    padding: 35px 35px 45px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item {
    padding: 45px 45px 55px;
  }
}

.products__item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 28px;
  opacity: 0;
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  transition: clip-path 0.25s ease-out, opacity 0.25s ease-out;
  background: transparent url(/assets/images/pattern-white.svg) repeat-x 50% 100%;
}

.products__tag {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6875rem;
  background-color: #00569d;
  color: white;
}

.products__item:focus-within {
  background-color: #0b1721;
  border-color: white;
}

.products__item:focus-within::after {
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.products__item:hover {
  background-color: #0b1721;
  border-color: white;
}

.products__item:hover::after {
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.products__heading {
  font-size: 1.5rem;
  line-height: 1.33333;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  margin: 0;
}

.products__image {
  margin: 0 0 20px;
}

.products__divider {
  display: inline-block;
  margin: 12px 0;
}

.products__talking-point {
  font-size: 0.75rem;
  line-height: 2.08333;
  font-weight: 300;
  margin: 0 0 0.5em;
}

.products__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.products .products__link,
.products .products__link:link,
.products .products__link:visited {
  text-decoration: none;
}

.products .products__link:hover,
.products .products__link:focus,
.products .products__link:active {
  text-decoration: underline;
}

.tablist {
  display: flex;
  margin: 0;
  padding: 0 0 15px;
  border-bottom: 5px #ebebeb solid;
  list-style-type: none;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.tablist.tablist--dark {
  border-bottom-color: #112c3e;
}

.tablist__item {
  margin-right: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .tablist__item {
    min-width: 145px;
  }
}

.tablist__item:last-child {
  margin-right: 0;
}

.tablist__link {
  font-size: 0.875rem;
  font-weight: 300;
  display: block;
  padding: 12px;
  text-align: center;
  border-radius: 3px;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.tablist__link, .tablist__link:link, .tablist__link:visited {
  text-decoration: none;
  background-color: #ebebeb;
  color: grey;
}

.tablist__link:hover, .tablist__link:focus, .tablist__link:active {
  background-color: #00569d;
  color: white;
}

.tablist__link.current, .tablist__link.current:link, .tablist__link.current:visited, .tablist__link.current:hover, .tablist__link.current:focus, .tablist__link.current:active {
  background-color: #00569d;
  color: white;
  cursor: default;
}

.tablist.tablist--dark .tablist__link, .tablist.tablist--dark .tablist__link:link, .tablist.tablist--dark .tablist__link:visited {
  background-color: #112c3e;
  color: white;
}

.tablist.tablist--dark .tablist__link:hover, .tablist.tablist--dark .tablist__link:focus, .tablist.tablist--dark .tablist__link:active {
  background-color: #00569d;
}

.tablist.tablist--dark .tablist__link.current, .tablist.tablist--dark .tablist__link.current:link, .tablist.tablist--dark .tablist__link.current:visited, .tablist.tablist--dark .tablist__link.current:hover, .tablist.tablist--dark .tablist__link.current:focus, .tablist.tablist--dark .tablist__link.current:active {
  background-color: #00569d;
}

.tabpanels {
  min-height: 500px;
}

.tabpanel {
  display: none;
}

.tabpanel:target {
  display: block;
}

.tabpanel.current {
  display: block;
}

.product-page {
  display: grid;
  background-color: #0d2433;
  color: white;
  padding: 50px 0;
}

@media screen and (min-width: 900px) {
  .product-page {
    padding: 70px 0;
  }
}

.product-page__talking-points {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: center;
  margin: 0 0 64px;
  padding: 0;
  gap: 30px;
}

@media screen and (min-width: 32em) {
  .product-page__talking-points {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

.product-page__talking-point {
  width: 150px;
  height: 150px;
  border: 5px #00569d solid;
  border-radius: 200px;
  font-size: 0.9375rem;
  font-weight: 300;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.product-page__talking-point span {
  font-size: 0.75rem;
  display: block;
  font-weight: bold;
  color: #0D92FF;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.product-page__introduction {
  display: grid;
  grid-gap: 40px;
  gap: 40px;
}

@media screen and (min-width: 1040px) {
  .product-page__introduction {
    grid-template-columns: 1fr 32em;
  }
}

@media screen and (min-width: 1600px) {
  .product-page__introduction {
    grid-gap: 80px;
    gap: 80px;
  }
}

.product-page__specification h3 {
  border-bottom: 5px #00569d solid;
  font-size: 1.5rem;
  line-height: 0.95833;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 18px;
}

.product-page__specification table {
  border: 0;
  margin: 0 0 2rem;
}

.product-page__specification tr {
  border-bottom: 1px #112c3e solid;
}

.product-page__specification tr td:first-child {
  padding-left: 0;
  width: 50%;
}

@media screen and (min-width: 800px) {
  .product-page__specification tr td:first-child {
    width: 20rem;
  }
}

.product-page__specification td,
.product-page__specification th {
  border: 0;
  font-weight: 300;
  font-size: 0.875rem;
}

.product-page__banner {
  padding: 0;
  margin-bottom: 60px;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.product-page__banner .centered {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}

@media screen and (min-width: 1024px) {
  .product-page__banner .centered {
    grid-template-columns: 2fr 1fr;
    align-items: end;
  }
}

.product-page.arctic .product-page__banner {
  padding-top: 300px;
  background-image: url("/assets/images/products/hero-arctic.jpg");
}

.product-page__product-info {
  text-align: right;
  font-family: "Titillium Web", sans-serif;
}

.product-page__product-info-line {
  font-size: 6.375rem;
  font-weight: bold;
  margin: 0;
  line-height: 1;
}

.product-page__product-info-name {
  font-size: 2.6875rem;
  font-weight: 300;
  margin: 0;
  line-height: 1;
}

.attachments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 40px;
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.attachments__item {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.attachments__info {
  margin: 0;
  line-height: 1.25;
}

.attachments__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 12rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  background-color: #112c3e;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  clip-path: polygon(0 0, calc(100% - 38px) 0, 100% 38px, 100% 100%, 0 100%, 0 0);
}

.attachments__link::before, .attachments__link::after {
  transition: transform 0.3s ease-out;
}

.attachments__link::before {
  transform: translateY(-6rem);
  margin-bottom: 0.5rem;
  content: url("data:image/svg+xml;utf8,%3Csvg width='19' height='19' viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M19,16.954l-19,-0l0,2l19,-0l0,-2Zm-10.5,-16.954l2.003,-0l0,10.56l2.536,-2.535l1.414,1.414l-4.95,4.95l-0.001,-0.002l-0.002,0.002l-4.95,-4.95l1.414,-1.414l2.536,2.536l0,-10.561Z' style='fill:%23fff;'/%3E%3C/svg%3E");
}

.attachments__link::after {
  content: "download";
  font-size: 0.875rem;
  font-weight: 300;
  transform: translateY(6rem);
}

.attachments__link, .attachments__link:link, .attachments__link:visited {
  text-decoration: none;
  color: #204761;
}

.attachments__link:hover, .attachments__link:focus, .attachments__link:active {
  background-color: #0058a2;
  color: #fff;
}

.attachments__link:hover::before, .attachments__link:hover::after, .attachments__link:focus::before, .attachments__link:focus::after, .attachments__link:active::before, .attachments__link:active::after {
  transform: translateY(0);
}

.attachments__name  {
  display: block;
  word-break: break-word;
}

.attachments__extension  {
  text-transform: uppercase;
  font-size: 1.75rem;
  font-weight: bold;
}

.attachments__size {
  font-size: 1.125rem;
  font-weight: 300;
}

@media screen and (min-width: 1200px) {
  #panel-features {
    min-height: 708px;
    background: transparent url(/assets/images/features-default.png) no-repeat 100% 100%;
  }
  .product-page.arctic #panel-features {
    background-image: url(/assets/images/features-arctic.png);
  }
}

.product-page__features ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.product-page__features li {
  margin: 0 0 45px;
  padding: 0 0 0 50px;
  line-height: 1.64286;
  font-size: 0.875rem;
  font-weight: 300;
  position: relative;
}

.product-page__features li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 18px;
  height: 7px;
  background: transparent url(/assets/images/wave.svg) no-repeat 0 0;
}

.product-page__features li h2,
.product-page__features li h3 {
  font-size: 1.5rem;
  line-height: 0.95833;
  font-weight: bold;
  margin: 0 0 0.75rem;
  padding: 0;
}

.product-page__features li p {
  margin: 0;
}

.support-page {
  margin: 50px 0;
}

@media screen and (min-width: 900px) {
  .support-page {
    margin: 70px 0;
  }
}

@media screen and (min-width: 1024px) {
  .support-page__content {
    margin-right: 330px;
  }
}

@media screen and (min-width: 1280px) {
  .support-page__content {
    max-width: 640px;
    margin-right: 480px;
  }
}

.support-page .tabpanel {
  position: relative;
}

.support-page .tip-bubble {
  display: none;
}

@media screen and (min-width: 1024px) {
  .support-page .tip-bubble {
    display: block;
    position: absolute;
    left: calc(100% + 30px);
    width: 300px;
  }
}

@media screen and (min-width: 1280px) {
  .support-page .tip-bubble {
    left: calc(100% + 80px);
    width: 400px;
  }
}

.support-downloads {
  margin-bottom: 3rem;
}

.support-downloads__heading {
  font-size: 2.125rem;
}

.support-downloads__list {
  margin: 0;
  padding: 0;
}

.support-downloads__item {
  margin-bottom: 1.5rem;
}

.support-downloads__subheading {
  border-bottom: 5px #00569d solid;
  color: #00569d;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 0;
}

.support-downloads__filelist {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.875rem;
}

.support-downloads__file {
  border-bottom: 2px #ebebeb solid;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.75rem 0;
}

@media screen and (min-width: 700px) {
  .support-downloads__file {
    grid-template-columns: 1fr 1fr;
  }
}

.support-downloads__filename {
  margin: 0;
  padding-right: 20px;
  color: #7a7a7a;
  word-break: break-word;
}

.support-downloads__link:hover, .support-downloads__link:focus, .support-downloads__link:active {
  color: #00569d;
}

.tip-bubble {
  background-color: #112c3e;
  color: white;
  border-radius: 20px 20px 20px 0;
  position: relative;
  font-family: "Titillium Web", sans-serif;
  line-height: 1.53846;
  font-size: 1.125rem;
  width: 100%;
  padding: 20px;
}

@media screen and (min-width: 700px) {
  .tip-bubble {
    padding: 30px;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1020px) {
  .tip-bubble {
    padding: 40px;
    font-size: 1.4375rem;
  }
}

@media screen and (min-width: 1200px) {
  .tip-bubble {
    font-size: 1.625rem;
    padding: 50px;
  }
}

.tip-bubble::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -1px;
  border-width: 46px 36px 0 0;
  border-color: #112c3e transparent transparent transparent;
  border-style: solid;
}

.faq__item {
  background-color: white;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
  padding: 25px 28px;
  border-radius: 20px;
  margin-bottom: 22px;
}

@media screen and (min-width: 900px) {
  .faq__item {
    padding: 35px 38px;
  }
}

.faq__question {
  line-height: 1.6;
  font-size: 1.25rem;
  color: #00569d;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  margin: 0;
  padding: 0;
}

.faq__answer {
  line-height: 1.85714;
  font-size: 0.875rem;
  color: #182b3c;
  margin: 0;
}

.default-page {
  padding: 50px 0;
}

@media screen and (min-width: 900px) {
  .default-page {
    padding: 70px 0;
  }
}

.page {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}

@media screen and (min-width: 800px) {
  .page {
    grid-template-columns: 1fr 350px;
  }
}

@media screen and (min-width: 1024px) {
  .page {
    grid-template-columns: 1fr 400px;
    grid-gap: 50px;
    gap: 50px;
  }
}

.page__header {
  padding-top: 70px;
}

@media screen and (min-width: 1280px) {
  .page {
    grid-template-columns: 1fr 490px;
    grid-gap: 100px;
    gap: 100px;
  }
}

.page__news {
  background-color: #00569d;
  color: white;
  padding: 25px 30px;
}

@media screen and (min-width: 800px) {
  .page__news {
    padding: 30px 40px;
  }
}

@media screen and (min-width: 1024px) {
  .page__news {
    padding: 40px 50px;
  }
}

@media screen and (min-width: 1600px) {
  .page__news {
    padding: 70px 80px;
  }
}

.news__heading {
  color: inherit;
  font-weight: bold;
  font-size: 2.75rem;
}

.news__item:not(:first-child) {
  margin-top: 46px;
}

.news__name {
  font-weight: 600;
  font-size: 1.1875rem;
  font-family: "Titillium Web", sans-serif;
  margin: 0;
}

.news__date {
  font-size: 0.875rem;
  color: #80BBEB;
  margin: 0;
}

.news__description {
  font-size: 0.8125rem;
  margin: 0;
}
