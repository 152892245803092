
.contact-form {
	&__heading {
		font-size: rem(40px);
		margin-top: 2rem;
	}

	&__label {

	}

	&__input {
		border-radius: 5px;
		border: 1px darken(#ebebeb, 10%) solid;
		background-color: #ebebeb;
		background-image: none;
		box-shadow: none;
		height: 50px;
		padding: .75rem 1rem;
	}

	&__textarea {
		resize: vertical;
		min-height: 200px;
	}
}