/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

@import "components/form", "components/contact-form";

/*
* LAYOUT
*/

//@import "layout/masonry";
@import "components/breadcrumb-nav";

.categories-overview {
	background-color: #132332;
	color: white;
	padding: 25px 0;

	@include min(900px) {
		padding: 40px 0;
	}
}

.categories {
	text-align: center;
	display: grid;
	grid-gap: 25px;
	gap: 25px;
	color: white;
	margin: 0;
	padding: 0;

	@include min(700px) {
		grid-template-columns: 1fr 1fr;
	}

	@include min(900px) {
		grid-gap: 40px;
		gap: 40px;
	}

	&__item {
		display: grid;
		grid-template-columns: 40px 1fr;
		grid-gap: 20px;
		gap: 20px;
		align-items: center;
		position: relative;
		text-align: left;
		padding: 16px;
		background-color: #182a3b;
		transition: background-color 0.3s ease-out;

		@include min(700px) {
			padding: 16px 20px;
			grid-template-columns: 54px 1fr;
		}

		@include min(1200px) {
			padding: 24px 30px;
			grid-gap: 30px;
			gap: 30px;
			grid-template-columns: 74px 1fr;
		}
	}

	&__item:focus-within {
		background-color: $color-brand;
	}

	&__item:hover {
		background-color: $color-brand;
	}

	&__icon {
		display: block;

		svg {
			width: 40px;
			height: 40px;

			@include min(700px) {
				width: 54px;
				height: 54px;
			}

			@include min(1200px) {
				width: 74px;
				height: 74px;
			}
		}
	}

	&__heading {
		font-family: $font-headings;
		font-size: rem(22px);
		line-height: (32 / 30);
		color: #0d92ff;
		font-weight: bold;
		margin: 0;

		@include min(700px) {
			font-size: rem(26px);
		}

		@include min(1200px) {
			font-size: rem(30px);
		}
	}

	&__description {
		font-size: rem(12px);
		margin-bottom: 0;
	}

	&__link {
		display: inline-flex;
		align-items: center;

		&::before {
			content: "";
			@include fill;
		}

		&::after {
			content: "";
			transition: transform 0.3s ease-in-out;
			transform: translateX(0);
			display: inline-block;
			margin-left: 12px;
			width: 20px;
			height: 20px;
			background: transparent
				encode-svg(
					'<svg width="14" height="9" viewBox="0 0 14 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M10.086,3.481l-10.086,-0l0,2l10.125,-0l-1.832,1.831l1.414,1.415l4.233,-4.246l-4.233,-4.208l-1.414,1.415l1.793,1.793Z" style="fill:#fff;"/></svg>'
				)
				no-repeat center center;
		}

		&:hover::after,
		&:focus::after {
			transform: translateX(0.5rem);
		}
	}

	@include link {
		color: white;
		text-decoration: none;
	}

	@include link-over {
	}
}

.products-page {
	background-color: #132332;
	color: white;
	padding: 50px 0;

	@include min(900px) {
		padding: 70px 0;
	}

	&__heading {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: rem(46px);
		margin: 0 0 3.5rem;
	}

	&__heading-icon {
		margin-right: 25px;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;

		svg {
			width: 40px;
			height: 40px;
		}

		@include min(1200px) {
			margin-right: 40px;

			svg {
				width: 60px;
				height: 60px;
			}
		}
	}
}

.products {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: grid;
	grid-gap: 25px;
	gap: 25px;

	@include min(700px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;
		gap: 40px;
	}

	@include min(1100px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 60px;
		gap: 60px;
	}

	&__item {
		border: 1px #1e425a solid;
		padding: 25px 25px 35px;
		position: relative;
		font-size: rem(12px);
		font-weight: 300;
		background: #101e2b url(/assets/images/pattern-products.svg) repeat-x 50%
			100%;
		transition: background-color 0.25s ease-out, border-color 0.25s ease-out;

		@include min(700px) {
			padding: 35px 35px 45px;
		}

		@include min(1200px) {
			padding: 45px 45px 55px;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 28px;
			opacity: 0;
			clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
			transition: clip-path 0.25s ease-out, opacity 0.25s ease-out;
			background: transparent url(/assets/images/pattern-white.svg) repeat-x
				50% 100%;
		}
	}

	&__tag {
		border-radius: 50%;
		width: 68px;
		height: 68px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: rem(11px);
		background-color: $color-brand;
		color: white;
	}

	&__item:focus-within {
		background-color: #0b1721;
		border-color: white;

		&::after {
			opacity: 1;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
	}

	&__item:hover {
		background-color: #0b1721;
		border-color: white;

		&::after {
			opacity: 1;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
	}

	&__heading {
		font-size: rem(24px);
		line-height: (32 / 24);
		font-weight: bold;
		font-family: $font-headings;
		margin: 0;
	}

	&__image {
		margin: 0 0 20px;
	}

	&__divider {
		display: inline-block;
		margin: 12px 0;
	}

	&__talking-point {
		font-size: rem(12px);
		line-height: (25 / 12);
		font-weight: 300;
		margin: 0 0 0.5em;
	}

	&__link {
		&::before {
			content: "";
			@include fill;
		}
	}

	@include link(".products__link") {
		text-decoration: none;
	}

	@include link-over(".products__link") {
		text-decoration: underline;
	}
}

.tablist {
	display: flex;
	margin: 0;
	padding: 0 0 15px;
	border-bottom: 5px #ebebeb solid;
	list-style-type: none;
	margin-bottom: 40px;
	flex-wrap: wrap;

	&.tablist--dark {
		border-bottom-color: #112c3e;
	}

	&__item {
		margin-right: 20px;
		margin-bottom: 20px;

		@include min(1024px) {
			min-width: 145px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		font-size: rem(14px);
		font-weight: 300;
		display: block;
		padding: 12px;
		text-align: center;
		border-radius: 3px;
		transition: background-color 0.15s ease-out, color 0.15s ease-out;

		@include link(&) {
			text-decoration: none;
			background-color: #ebebeb;
			color: grey;
		}

		@include link-over(&) {
			background-color: $color-brand;
			color: white;
		}

		&.current {
			@include link-all(&) {
				background-color: $color-brand;
				color: white;
				cursor: default;
			}
		}
	}

	&.tablist--dark {
		.tablist__link {
			@include link(&) {
				background-color: #112c3e;
				color: white;
			}

			@include link-over(&) {
				background-color: $color-brand;
			}

			&.current {
				@include link-all(&) {
					background-color: $color-brand;
				}
			}
		}
	}
}

.tabpanels {
	min-height: 500px;
}

.tabpanel {
	display: none;

	&:target {
		display: block;
	}

	&.current {
		display: block;
	}
}

.product-page {
	display: grid;
	background-color: #0d2433;
	color: white;
	padding: 50px 0;

	@include min(900px) {
		padding: 70px 0;
	}

	&__talking-points {
		display: flex;
		flex-direction: column;
		list-style-type: none;
		align-items: center;
		margin: 0 0 64px;
		padding: 0;
		gap: 30px;

		@include min(32em) {
			flex-direction: row;
			justify-content: space-between;
			gap: 0;
		}
	}

	&__talking-point {
		width: 150px;
		height: 150px;
		border: 5px #00569d solid;
		border-radius: 200px;
		font-size: rem(15px);
		font-weight: 300;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 1rem;

		span {
			font-size: rem(12px);
			display: block;
			font-weight: bold;
			color: #0D92FF;
			text-transform: uppercase;
			margin-bottom: 6px;
		}
	}

	&__introduction {
		display: grid;
		grid-gap: 40px;
		gap: 40px;

		@include min(1040px) {
			grid-template-columns: 1fr 32em;
		}

		@include min(1600px) {
			grid-gap: 80px;
			gap: 80px;
		}
	}

	&__specification {
		h3 {
			border-bottom: 5px $color-brand solid;
			font-size: rem(24px);
			line-height: (23 / 24);
			text-transform: uppercase;
			font-weight: bold;
			padding-bottom: 18px;
		}

		table {
			border: 0;
			margin: 0 0 2rem;
		}

		tr {
			border-bottom: 1px #112c3e solid;

			td:first-child {
				padding-left: 0;
				width: 50%;

				@include min(800px) {
					width: 20rem;
				}
			}
		}

		td,
		th {
			border: 0;
			font-weight: 300;
			font-size: rem(14px);
		}
	}

	&__banner {
		padding: 0;
		margin-bottom: 60px;
		background: {
			position: 50% 0;
			repeat: no-repeat
		}

		.centered {
			display: grid;
			grid-gap: 30px;
			gap: 30px;

			@include min(1024px) {
				grid-template-columns: 2fr 1fr;
				align-items: end;
			}
		}
	}

	&.arctic .product-page__banner {
		padding-top: 300px;
		background-image: url("/assets/images/products/hero-arctic.jpg");
	}

	&__product-info {
		text-align: right;
		font-family: $font-headings;
	}
	&__product-info-line {
		font-size: rem(102px);
		font-weight: bold;
		margin: 0;
		line-height: 1;
	}
	&__product-info-name {
		font-size: rem(43px);
		font-weight: 300;
		margin: 0;
		line-height: 1;
	}
}

.attachments {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
	grid-gap: 40px;
	gap: 40px;
	margin: 0;
	padding: 0;
	list-style-type: none;

	&__item {
		font-family: $font-headings;
		font-size: rem(20px);
		font-weight: bold;
	}
	&__info {
		margin: 0;
		line-height: 1.25;
	}
	&__link {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 12rem;
		overflow: hidden;
		margin-bottom: 0.5rem;
		background-color: #112c3e;
		transition: background-color 0.2s ease-out, color 0.2s ease-out;
		clip-path: polygon(
			0 0,
			calc(100% - 38px) 0,
			100% 38px,
			100% 100%,
			0 100%,
			0 0
		);

		&::before,
		&::after {
			transition: transform 0.3s ease-out;
		}
		&::before {
			transform: translateY(-6rem);
			margin-bottom: 0.5rem;
			content: encode-svg(
				'<svg width="19" height="19" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M19,16.954l-19,-0l0,2l19,-0l0,-2Zm-10.5,-16.954l2.003,-0l0,10.56l2.536,-2.535l1.414,1.414l-4.95,4.95l-0.001,-0.002l-0.002,0.002l-4.95,-4.95l1.414,-1.414l2.536,2.536l0,-10.561Z" style="fill:#fff;"/></svg>'
			);
		}
		&::after {
			content: "download";
			font-size: rem(14px);
			font-weight: 300;
			transform: translateY(6rem);
		}

		@include link(&) {
			text-decoration: none;
			color: #204761;
		}

		@include link-over(&) {
			background-color: #0058a2;
			color: #fff;

			&::before,
			&::after {
				transform: translateY(0);
			}
		}
	}
	&__name  {
		display: block;
		word-break: break-word;
	}

	&__extension  {
		text-transform: uppercase;
		font-size: rem(28px);
		font-weight: bold;
	}
	&__size {
		font-size: rem(18px);
		font-weight: 300;
	}
}

#panel-features {
	@include min(1200px) {
		min-height: 708px;
		background: transparent url(/assets/images/features-default.png) no-repeat 100% 100%;

		.product-page.arctic & {
			background-image: url(/assets/images/features-arctic.png);
		}
	}
}

.product-page__features {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0 0 45px;
		padding: 0 0 0 50px;
		line-height: (23 / 14);
		font-size: rem(14px);
		font-weight: 300;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0.6em;
			width: 18px;
			height: 7px;
			background: transparent url(/assets/images/wave.svg) no-repeat 0 0;
		}

		h2,
		h3 {
			font-size: rem(24px);
			line-height: (23 / 24);
			font-weight: bold;
			margin: 0 0 0.75rem;
			padding: 0;
		}

		p {
			margin: 0;
		}
	}
}

.support-page {
	margin: 50px 0;

	@include min(900px) {
		margin: 70px 0;
	}

	&__content {
		@include min(1024px) {
			margin-right: 330px;
		}

		@include min(1280px) {
			max-width: $max-width / 2;
			margin-right: 480px;
		}
	}

	.tabpanel {
		position: relative;
	}

	.tip-bubble {
		display: none;

		@include min(1024px) {
			display: block;
			position: absolute;
			left: calc(100% + 30px);
			width: 300px;
		}

		@include min(1280px) {
			left: calc(100% + 80px);
			width: 400px;
		}
	}
}

.support-downloads {
	margin-bottom: 3rem;

	&__heading {
		font-size: rem(34px);
	}
	&__list {
		margin: 0;
		padding: 0;
	}
	&__item {
		margin-bottom: 1.5rem;
	}
	&__subheading {
		border-bottom: 5px $color-brand solid;
		color: $color-brand;
		text-transform: uppercase;
		font-size: rem(24px);
		font-weight: bold;
		margin: 0;
		padding: 0.5rem 0;
	}
	&__filelist {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: rem(14px);
	}
	&__file {
		border-bottom: 2px #ebebeb solid;
		display: grid;
		grid-template-columns: 1fr auto;
		padding: 0.75rem 0;

		@include min(700px) {
			grid-template-columns: 1fr 1fr;
		}
	}
	&__filename {
		margin: 0;
		padding-right: 20px;
		color: #7a7a7a;
		word-break: break-word;
	}
	&__link {
		@include link-over(&) {
			color: $color-brand;
		}
	}
}

.tip-bubble {
	background-color: #112c3e;
	color: white;
	border-radius: 20px 20px 20px 0;
	position: relative;
	font-family: $font-headings;
	line-height: (40 / 26);
	font-size: rem(18px);
	width: 100%;
	padding: 20px;

	@include min(700px) {
		padding: 30px;
		font-size: rem(20px);
	}
	@include min(1020px) {
		padding: 40px;
		font-size: rem(23px);
	}
	@include min(1200px) {
		font-size: rem(26px);
		padding: 50px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: -1px;
		border-width: 46px 36px 0 0;
		border-color: #112c3e transparent transparent transparent;
		border-style: solid;
	}
}

.faq {
	&__item {
		background-color: white;
		box-shadow: 0 0 33px rgba(black, 0.1);
		padding: 25px 28px;
		border-radius: 20px;
		margin-bottom: 22px;

		@include min(900px) {
			padding: 35px 38px;
		}
	}

	&__question {
		line-height: (32 / 20);
		font-size: rem(20px);
		color: $color-brand;
		font-weight: bold;
		font-family: $font-headings;
		margin: 0;
		padding: 0;
	}

	&__answer {
		line-height: (26 / 14);
		font-size: rem(14px);
		color: #182b3c;
		margin: 0;
	}
}

.default-page {
	padding: 50px 0;

	@include min(900px) {
		padding: 70px 0;
	}
}

.page {
	display: grid;
	grid-gap: 30px;
	gap: 30px;

	@include min(800px) {
		grid-template-columns: 1fr 350px;
	}

	@include min(1024px) {
		grid-template-columns: 1fr 400px;
		grid-gap: 50px;
		gap: 50px;
	}

	&__header {
		padding-top: 70px;
	}

	@include min(1280px) {
		grid-template-columns: 1fr 490px;
		grid-gap: 100px;
		gap: 100px;
	}

	&__body {

	}

	&__news {
		background-color: $color-brand;
		color: white;
		padding: 25px 30px;

		@include min(800px) {
			padding: 30px 40px;
		}

		@include min(1024px) {
			padding: 40px 50px;
		}

		@include min(1600px) {
			padding: 70px 80px;
		}
	}
}

.news__heading {
	color: inherit;
	font-weight: bold;
	font-size: rem(44px);
}

.news__list {

}
.news__item {
	&:not(:first-child) {
		margin-top: 46px;
	}
}
.news__name {
	font-weight: 600;
	font-size: rem(19px);
	font-family: $font-headings;
	margin: 0;
}
.news__date {
	font-size: rem(14px);
	color: #80BBEB;
	margin: 0;
}
.news__description {
	font-size: rem(13px);
	margin: 0;
}
