.breadcrumb-nav {
	background-color: #182a3b;
	&__list {
		margin: 0;
		padding: 23px 0;
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		justify-content: flex-start;
		color: white;

		@include min(800px) {
			justify-content: flex-end;
		}
	}

	&__item {
		font-size: rem(14px);
		&:not(:last-child)::after {
			content: "\>";
			margin: 0 0.6em 0 0.75em;

			@include min(800px) {
				margin: 0 1.2em 0 1.5em;
			}
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;
	}

	@include link {
		color: white;
		text-decoration: underline;
	}

	@include link('[aria-current="location"]') {
		text-decoration: none;
		cursor: default;
		pointer-events: none;
	}

	@include link-over {
		text-decoration: none;
	}
}
